<template>
  <nav class="flix-navbar flix-navbar-expand-lg flix-navbar-light flix-navbar-default"  v-if="$store.getters.shares && $store.getters.shares.length" :key="updateKey">
    <div class="flix-container-fluid flix-html-html flix-text-center">
      <div class="flix-navbar-collapse">
        <div class="flix-navbar-brand">{{ $t("message.sharedWithMe") }}</div>
        <ul class="flix-html-ul flix-nav flix-navbar-nav flix-navbar-left">
          <li class="flix-html-li flix-dropdown" :class="{'flix-open': open === index}" v-for="(ressource, index) in $store.getters.shares" :key="index"><a :style="{'background-color': getBackgroundColor(index), 'border-bottom': '3px solid ' + ressource.info.ressource.color}" href="#" @click.prevent="setOpen(index)" class="flix-dropdown-toggle flix-html-a"><flixIcon :id="'share'" /> <b>{{ ressource.info.ressource.title }}</b> <flixIcon :id="'user'" /> {{ ressource.info.username }}</a>
            <ul class="flix-dropdown-menu flix-dropdown-menu-right" :style="{'border-color': ressource.info.ressource.color}">
              <li class="flix-html-li" :class="{'flix-active': active[0] === index && active[1] === index2}" :style="{'border-left': '4px solid ' + ressource.info.ressource.color}"  v-for="(calendar, index2) in ressource.calendar" :key="index2"><router-link @click.native="setActive(index, index2)" :to="{name: 'sharesCalendar', params: { ressource: index, calendar: index2 }}" class="flix-html-a" ><flixIcon :id="'calendar'" /> {{ calendar.label }}</router-link></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data () {
    return {
      updateKey: new Date().getTime(),
      open: false,
      active: [0, 0]
    }
  },
  methods: {
    getBackgroundColor (index) {
      if (index === this.active[0]) {
        return '#eee'
      }
      return 'inherit'
    },
    setActive (index, index2) {
      this.active = [index, index2]
      this.$store.commit('setShareNav', this.active)
      this.open = false
    },
    setOpen (index) {
      if (this.open === index) {
        this.open = false
        return false
      }
      this.open = index
    }
  },
  computed: {
  },
  mounted () {
    var email = this.$store.getters.shareEmail
    var value = this.$store.getters.user

    if (typeof value === 'object' && typeof value.email === 'string') {
      email = value.email
    }
    this.$store.dispatch('getShares', {
      email: email,
      callback: function () {
        this.active = this.$store.getters.getShareNav
        this.updateKey = new Date().getTime()
      }.bind(this)
    })
  }
}
</script>
<style scoped lang="sass">
  @import '@/assets/style/sass/stylesheet/flix_color_style.scss'
  .flix-navbar-default .flix-navbar-brand
    font-size: 20pt
    padding-top: 36px
    margin-bottom: 26.5px
  .flix-dropdown
    .flix-dropdown-menu
      min-width: 300px
    .flix-html-a
      margin-bottom: 0
      clear: both
      word-wrap: break-word
      word-break: normal
      white-space: normal
  .router-link-exact-active
    border-bottom: 2px solid $brand-primary
</style>
